import Img from "gatsby-image/index";
import { Link } from "gatsby-plugin-react-i18next";
import React from "react";
import { createSlug } from "../../../utils/slug-creator";

const TeamMembers = ({ teamMembers }) => {
  return (
    <div className="scientist-area bg-color pt-100 pb-70">
      <div className="container">
        <div className="row">
          {teamMembers.map((teamMember) => (
            <Link
              to={`/team/${createSlug(teamMember.name)}`}
              className="d-block col-lg-3 col-sm-6 "
              key={teamMember.name}
            >
              <div className="single-scientist-box">
                <div className="image d-flex justify-content-center ">
                  <Img
                    alt={`${teamMember.name} - ${teamMember.role}`}
                    className="team-picture"
                    style={{ height: "100%", width: "100%" }}
                    fluid={teamMember.image.childImageSharp.fluid}
                  />
                </div>
                <div className="content">
                  <h3>{teamMember.name}</h3>
                  <span>{teamMember.role}</span>
                  <ul className="social">
                    <li>
                      <a
                        href={`mailto:${teamMember.email}`}
                        className="d-block"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="bx bx-envelope"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href={teamMember.linkedinUrl}
                        className="d-block"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="bx bxl-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                  {/*<p className="pt-4 font-weight-normal text-left">*/}
                  {/*  {teamMember.description}*/}
                  {/*</p>*/}
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TeamMembers;
