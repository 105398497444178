import { graphql, useStaticQuery } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import React from "react";
import SEO from "../timewise-custom/components/App/SEO";
import PageBanner from "../components/Common/PageBanner";
import Layout from "../timewise-custom/components/App/Layout";
import Navbar from "../timewise-custom/components/App/Navbar";
import TeamMembers from "../timewise-custom/components/Team/TeamMembers";
import { markdownUnwrapper } from "../utils/markdown-unwrapper";

const Team = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        sort: { fields: [frontmatter___de___priority], order: ASC }
        filter: { frontmatter: { de: { template: { eq: "team-member" } } } }
      ) {
        edges {
          node {
            frontmatter {
              de {
                description
                linkedinUrl
                email
                name
                role
                image {
                  childImageSharp {
                    fluid(maxWidth: 300, quality: 70) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              en {
                description
                role
              }
            }
          }
        }
      }
    }
  `);
  const { language, t } = useI18next();
  const teamMembers = markdownUnwrapper(data, language);

  return (
    <Layout>
      <SEO title={t("navigation:team")} />
      <Navbar />
      <PageBanner
        pageTitle={t("navigation:team")}
        homePageText={t("navigation:home")}
        homePageUrl="/"
        activePageText={t("navigation:team")}
      />
      <TeamMembers teamMembers={teamMembers} />
    </Layout>
  );
};

export default Team;
